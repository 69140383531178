/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout, ProfileCard, ProjectCard } from '@components';

const StyledMainContainer = styled.main`
  counter-reset: section;
  margin-top: 140px;
`;

const ProjectsPage = props => {
  const { location, data } = props;
  const nodes = data.featured.edges;
  const [projectsList, setProjectsList] = useState([]);

  const parseProjects = () => {
    const list = [];
    for (let index = 0; index < nodes.length; index++) {
      const edge = nodes[index];
      const { id, frontmatter } = edge.node;

      list.push({
        id,
        frontmatter,
      });
    }

    setProjectsList(list);
  };

  useEffect(() => {
    parseProjects();
  }, []);

  return (
    <Layout location={location}>
      <StyledMainContainer className="fillHeight">
        <ProfileCard />

        <div className="mt-24">
          <h2 className="heading-with-lines justify-center">Take a look at my work!</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {projectsList.map(project => (
            <ProjectCard key={project.id} frontmatter={project.frontmatter} />
          ))}
        </div>
      </StyledMainContainer>
    </Layout>
  );
};

ProjectsPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    featured: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/projects/" }
      }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            cover {
              childImageSharp {
                gatsbyImageData(width: 1400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            summary
            external
            tech
            github
            projectSource
          }
          html
        }
      }
    }
  }
`;

export default ProjectsPage;
